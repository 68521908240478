import { IBadgeResponse } from 'app/(app)/dashboard/badges/badges.typings';
import { IBadgeItem } from './badges.typings';
import dayjs from 'dayjs';
import { DATE_FORMATS } from '@constants/date.constants';

export function formatBadgeResponse(badge: IBadgeResponse): IBadgeItem {
  return {
    name: badge?.name,
    personalNote: badge?.description,
    date: dayjs(badge?.created_at).format(DATE_FORMATS.badge),
    image: badge.external_url,
    amount: badge.quantity,
    id: badge._id,
    type: [1, 2, '1', '2'].includes(badge.type) ? badge.type : 1,
    publicLink: badge.public_link,
    platform: badge.media_source,
    collaboration_enabled: badge.collaboration_enabled,
    collaboration_status: badge.collaboration_status,
    collaborator: badge.collaborator,
    draft: badge.draft,
    author: badge.author,
    color: badge.custom_badge_color,
    badgeNumber: badge.serial_number,
    badgeAmount: badge.total_quantity,
    perks: badge.perquisites,
    caption: badge.caption,
    allowedDomain: badge.allowedDomain,
    message: badge?.message,
    availability: badge?.availability,
    owner: badge?.owner,
    expired: badge?.expired,
  };
}

export const BadgeStatusMap = {
  draft: {
    text: 'Draft',
    showUsername: false,
    pillClasses: 'bg-bgGrey',
    textClasses: 'text-[#707B8F]',
  },
  pendingAuthor: {
    text: 'Pending',
    showUsername: false,
    pillClasses: 'bg-[#FADF9A]',
    textClasses: 'text-[#937C04]',
  },
  pendingCollaborator: {
    text: 'wants to collaborate',
    showUsername: true,
    pillClasses: 'bg-white',
    textClasses: 'text-subtitle',
  },
  accepted: {
    text: '',
    showUsername: true,
    pillClasses: 'bg-white',
    textClasses: 'text-subtitle',
  },
  rejected: {
    text: 'Rejected',
    showUsername: false,
    pillClasses: 'bg-[#FFC0B3]',
    textClasses: 'text-error',
  },
  default: {
    text: '',
    showUsername: false,
    pillClasses: '',
    textClasses: '',
  },
};

export type IBadgeStatus = keyof typeof BadgeStatusMap;

export function getBadgeStatus(badge: IBadgeItem, userId: string): IBadgeStatus {
  if (badge.draft) {
    return 'draft';
  }

  if (!badge.collaboration_enabled) {
    return 'default';
  }

  if (badge.collaboration_status === 'pending') {
    return badge.author === userId
      ? 'pendingAuthor'
      : badge.collaborator === userId
      ? 'pendingCollaborator'
      : 'default';
  }

  if (badge.collaboration_status === 'rejected' || badge.collaboration_status === 'accepted') {
    return badge.collaboration_status;
  }

  return 'default';
}
